#about-content {
	color: aliceblue;
	/* font-size: 20px; */
	/* padding: 10px; */
    text-align:justify;
    font-family: sans-serif;
    border-style: none;
}

.heading {
    font-family: 'Montserrat', sans-serif;
    margin-top: 4%;
    border: none;
	margin-bottom: -3%;
}